@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.k-progress-circular {
  .progress {
    text-align: center;
  }
}
.v-slider__thumb-label__container {
  top: 10px;
}
.v-slider__thumb-label {
  border-radius: 50% 50% 50% !important;
}
.v-slider__thumb {
  display: none;
}
.v-slider__track-fill,
.v-slider__track,
.v-slider__track__container {
  height: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
